import {ModalBase} from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";

export class ListEmailDefectModal extends ModalBase {
    constructor() {
        super('list_email_defect_modal');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = app.CACHE.URL_AJAX + 'veh_def_email/' + $modalTriggerBtn.attr('data-id'),
                $email = this.MODAL_CONTAINER_EL.find('#email'),
                $email_notes = this.MODAL_CONTAINER_EL.find('#email_notes');
            const errors = checkErrors($modalTriggerBtn, [$email, $email_notes]);

            // start
            loading('start', $modalTriggerBtn);

            // re-open modal
            if( errors.length > 0 ) {
                loading('end', $modalTriggerBtn);
                return false;
            }

            const data = {
                'tbl': $modalTriggerBtn.attr('data-tbl'),
                'email': $email.val(),
                'email_notes': $email_notes.val()
            };

            AjaxSync({
                'method': 'POST',
                'url': url,
                'data': data
            }, {
                done: (res) => {
                    // start
                    loading('end', $modalTriggerBtn);

                    if( res.status === 'success' ) {

                        // remove from dom
                        alert('Email successfully sent to ' + data.email);

                        // reset form
                        form_reset(this.MODAL_CONTAINER_EL);

                    } else if( res.status === 'error') {

                        checkErrorsAjax($modalTriggerBtn, res.errors);

                    } else {
                        console.log(res);
                        alert("There was an error, contact administrator");
                    }
                },
                error: (err) => {
                    loading('end', $modalTriggerBtn);
                    console.log(err);
                    alert("There was an error, contact administrator");
                }
            });
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}